import $ from 'jquery';

$(document).ready(function () {

    $( "tr.branche td" ).mouseover(function(event) {
        var kanton = $( this ).data( "kanton" );
        $( "tr.branche td" ).removeClass( "hover" );
        $( "tr.branche td.kanton-" + kanton ).addClass( "hover" );
    });

    $( "table.kautionsmatrix" ).mouseout(function(event) {
        $( "tr.branche td" ).removeClass( "hover" );
    });

    $( "#inputPLZselectInner ul li" ).click(function(event) {
        var data = $( this ).data( "plzOrtKanton" );

        var res = data.split("|");

        var plz     = res[0];
        var ort     = res[1];
        var kanton  = res[2];

        setPlzOrtData(plz, ort, kanton);
        $( "#inputPLZselectInner" ).hide();
    });

    // kautionsmatrix füllen
    var arrKantone = ['AG','AI','AR','BE','BL','BS','FR','GE','GL','GR','JU','LU','NE','NW','OW','SG','SH','SO','SZ','TG','TI','UR','VD','VS','ZG','ZH'];
    $('.kautionsmatrix tr.branche').each(function(index, tr) {
        var brancheId = $( this ).data( "branche" );
        for (var i=0;i<arrKantone.length;i++) {
            var html = $( ".raw.branche-" + brancheId + ".kanton-" + arrKantone[i] ).html();
            $( ".kautionsmatrix tr.branche-" + brancheId + " .kanton-" + arrKantone[i] ).html(html);
        }
    });


    // ort/kanton zu plz holen
    $( "#inputPLZ" ).keyup(function() {

        $( "#inputOrt" ).val('');
        $( "#inputKanton" ).val('');
        $("#inputPLZselectInner").html('');
        $("#inputPLZselectInner").hide('');

        var inputPLZ = $( "#inputPLZ" ).val();
        var plz = "";

        // empty kanton if set
        $( "#inputKanton" ).val('');
        $( "#inputOrt" ).val('');

        if (inputPLZ.length == 4 && inputPLZ > 999 && inputPLZ < 10000) {
            plz = inputPLZ;
        } else if (inputPLZ.length > 2) {
            if (inputPLZ.length > 3) {
                var r = /\d+/;
                if (inputPLZ.match(r) > 999 &&  inputPLZ.match(r) < 10000 ) {
                    plz = inputPLZ.match(r);
                }
            }
        }

        if (plz != "" && plz > 999) {
            getOrtByPLZ(plz);
        }

    });

    $( "#inputOrt" ).keyup(function() {

        $( "#inputPLZ" ).val('');
        $( "#inputKanton" ).val('');
        $("#inputPLZselectInner").html('');
        $("#inputPLZselectInner").hide('');

        var inputOrt = $( "#inputOrt" ).val();
        if (inputOrt.length > 2) {
            var ort = inputOrt.charAt(0).toUpperCase() + inputOrt.substring(1).toLowerCase();
            getPLZByOrt(ort);
        }
    });


    $( "#inputTaetigkeit" ).change(function() {
        $( "#inputBranche" ).val('');
    });
    $( "#inputBranche" ).change(function() {
        $( "#inputTaetigkeit" ).val('');
    });
    $( "#inputKanton" ).change(function() {
        $( "#inputPLZhidden" ).val('');
        $( "#inputPLZ" ).val('');
        $("#inputOrt").val('');
    });


    $("#resetBtn").click(function(event) {

        $("#inputBranche").val('');
        $("#inputTaetigkeit").val('');

        $("#inputOrt").val('');
        $("#inputPLZ").val('');
        $("#inputPLZhidden").val('');
        $("#inputKanton").val('');

        $("#inputPLZselectInner").html('');
        $("#inputPLZselectInner").addClass('hidden');
        $("#inputPLZselectInner").hide('');

        event.preventDefault();
        event.stopPropagation();

    });

});


function getOrtByPLZ(plz) {

    var setPLZ = plz;
    console.log("function getOrtByPLZ: " + plz);

    // empty kanton if set
    $( "#inputKanton" ).val('');
    $( "#inputOrt" ).val('');
    $("#inputPLZselectInner").html('');
    $("#inputPLZselectInner").hide('');

    var debug = 0;

    var ajaxurl = "https://swisspost.opendatasoft.com/api/records/1.0/search/?dataset=plz_verzeichnis_v2&q=" + plz
    + "&facet=postleitzahl&refine.postleitzahl=" + plz;

    if (debug == 1) console.log("click: #buttonPLZ");
    if (debug == 1) console.log("plz:" + plz);
    if (debug == 1) console.log("ajaxurl" + ajaxurl);

    $.getJSON( ajaxurl, function( data ) {

        var treffer = 0;
        var select = "";
        var kanton = "";
        var plz = "";
        var ort = "";
        var lastOrt = "";
        var plz_typ = "";

        var kantonShow  = "";
        var plzShow     = "";
        var ortShow     = "";

        $.each( data, function( key, val ) {

            if (key == "records") {

                var records = data["records"];

                if (debug == 2) {
                    console.log("records: ");
                    console.log(records);
                }

                $.each(records, function( index, record ) {

                    kanton  = record.fields.kanton;
                    plz     = record.fields.gplz;
                    ort     = record.fields.ortbez27;
                    plz_typ = record.fields.plz_typ;

                    if (debug == 1) {
                        console.log("kanton: " + kanton);
                        console.log("lastOrt: " + lastOrt);
                        console.log("ort: " + ort);
                        console.log("lastOrt.length: " + lastOrt.length);
                        console.log("ort.length: " + ort.length);
                    }

                    if (plz_typ == 10 || plz_typ == 20) {

                        if (ort.length < lastOrt.length || lastOrt == "") {

                            treffer = 1;

                            if (debug == 2)console.log("aktueller ort: " + ort);
                            if (debug == 2)console.log("lastOrt ort: " + lastOrt);

                            kantonShow  = record.fields.kanton;
                            plzShow     = record.fields.gplz;
                            ortShow     = record.fields.ortbez27;

                            select = select + "<li data-ort='" + record.fields.ortbez27 + "'>" + record.fields.gplz + " " + record.fields.ortbez27 + " (" + record.fields.kanton + ")</li>";

                            if (debug == 2) {
                                console.log("fields: ");
                                console.log(record.fields);
                                console.log("record.fields.kanton: " + record.fields.kanton);
                                console.log("record.fields.ortbez18: " + record.fields.ortbez18);
                                console.log("record.fields.ortbez27: " + record.fields.ortbez27);
                                console.log("record.fields.gplz: " + record.fields.gplz);
                                console.log("record.fields.postleitzahl: " + record.fields.postleitzahl);
                                console.log("record.fields.sprachcode: " + record.fields.sprachcode);
                            }

                            lastOrt = ort;

                        }

                    }

                });

            }

        });

        if (debug == 1)console.log("------");
        if (debug == 1)console.log("treffer: " + treffer);
        if (debug == 1)console.log("kantonShow: " + kantonShow);
        if (debug == 1)console.log("plzShow: " + plzShow);
        if (debug == 1)console.log("ortShow: " + ortShow);
        if (debug == 1)console.log("------");

        if (treffer == 1) {
            setPlzOrtData(setPLZ, ortShow, kantonShow);
        } else if (treffer > 1) {
            $("#inputPLZselectInner").html("<ul>" + select + "</ul>");
        } else {
            var keineTrefferText = $( "#resetBtn" ).data( "text" );
            $("#inputOrt").val(keineTrefferText);
            $("#inputPLZhidden").val("");
        }

    });

}

function getPLZByOrt(ort) {

    console.log("function getPLZByOrt: " + ort);

    // empty kanton if set
    $( "#inputKanton" ).val('');
    $( "#inputPLZ" ).val('');
    $("#inputPLZselectInner").html('');
    $("#inputPLZselectInner").hide('');

    var debug = 0;

    var ajaxurl = "https://swisspost.opendatasoft.com/api/records/1.0/search/?dataset=plz_verzeichnis_v2&q=" + ort
        + "&rows=50&sort=-gplz&facet=plz_zz&facet=ortbez18&facet=ortbez27";

    if (debug == 1) console.log("click: #buttonPLZ");
    if (debug == 1) console.log("ort:" + ort);
    if (debug == 1) console.log("ajaxurl" + ajaxurl);

    $.getJSON( ajaxurl, function( data ) {

        var html = "";
        var select = "";
        var kanton = "";
        var plz = "";
        var ort = "";
        var plz_typ = "";
        var treffer = 0;
        var lastPlz = "";
        var lastOrt = "";
        var lastKanton = "";

        $.each( data, function( key, val ) {

            if (key == "records") {

                var records = data["records"];

                if (debug == 2) {
                    console.log("records: ");
                    console.log(records);
                }

                $.each(records, function( index, record ) {

                    kanton  = record.fields.kanton;
                    plz     = record.fields.gplz;
                    ort     = record.fields.ortbez27;
                    plz_typ = record.fields.plz_typ;

                    if (plz_typ == 10 || plz_typ == 20) {

                        if (lastPlz != plz || lastOrt.length > ort.length || lastKanton != kanton || lastPlz == "") {

                            treffer++;

                            html = record.fields.gplz + " " + record.fields.ortbez27;
                            select = select + "<li data-plz='" + record.fields.gplz + "|" + record.fields.ortbez27 + "|" + record.fields.kanton + "'>" + record.fields.gplz + " <span>" + record.fields.ortbez27 + " (" + record.fields.kanton + ")</span></li>";
                            lastPlz = plz;
                            lastOrt = ort;
                            lastKanton = kanton;
                            if (debug == 1) {
                                console.log("plz/ort/kanton: " + plz + ort + kanton);
                                console.log("plz_typ: " + plz_typ + ort + kanton);
                            }

                        }
                    }

                    if (debug == 2) {
                        console.log("fields: ");
                        console.log(record.fields);
                        console.log("record.fields.kanton: " + record.fields.kanton);
                        console.log("record.fields.ortbez18: " + record.fields.ortbez18);
                        console.log("record.fields.ortbez27: " + record.fields.ortbez27);
                        console.log("record.fields.gplz: " + record.fields.gplz);
                        console.log("record.fields.postleitzahl: " + record.fields.postleitzahl);
                        console.log("record.fields.sprachcode: " + record.fields.sprachcode);
                    }

                });

            }

        });

        if (debug == 1)console.log("------");
        if (debug == 1)console.log("treffer: " + treffer);
        if (debug == 1)console.log("kantonShow: " + lastKanton);
        if (debug == 1)console.log("plzShow: " + lastPlz);
        if (debug == 1)console.log("ortShow: " + lastOrt);
        if (debug == 1)console.log("------");

        $("#inputPLZselectInner").empty();
        $( "#inputPLZselectInner" ).hide();

        if (treffer == 1) {
            setPlzOrtData(lastPlz, lastOrt, lastKanton);
        } else if (treffer > 1) {
            $("#inputPLZselectInner").html("<ul>" + select + "</ul>");
            $("#inputPLZselectInner").show();
        } else {
            var keineTrefferText = $( "#resetBtn" ).data( "text" );
            $("#inputPLZ").val(keineTrefferText);
            $("#inputPLZhidden").val("");
        }

    });

}

function setPlzOrtData(plz, ort, kanton) {
    $( "#inputKanton" ).val(kanton).change();
    $( "#inputPLZ" ).val(plz);
    $( "#inputPLZhidden" ).val(plz);
    $( "#inputOrt" ).val(ort);
}


$(document).on("#inputPLZselectInner click", 'ul li', function(event) {

    var data = $( this ).data( "plz" );

    console.log("data");
    console.log(data);

    var res = data.split("|");

    var plz     = res[0];
    var ort     = res[1];
    var kanton  = res[2];

    setPlzOrtData(plz, ort, kanton);
    $( "#inputPLZselectInner" ).hide();
});
